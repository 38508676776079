import { CSSProperties } from 'react';

const SETTINGS_MAP = [
  {type: 'switch', key: 'form_showKeys', label: 'Show form keys'},
  {type: 'display', key: 'v+token', label: 'Current user token'},
  {type: 'display', key: 'organization_id', label: 'Current organization id'},
  {type: 'switch', key: 'LOCAL_SKIP_MT_LOCK', label: 'Skip maintanance lock'},
  {type: 'switch', key: 'SHOW_TOKEN_REFRESH_LOGS', label: 'Show token refresh logs'},
  {type: 'switch', key: 'SHOW_PLAN_AS_JSON', label: 'Show plan in administrative mode as a JSON'},
];

export class DevTool {
  private isEnabled: boolean;
  private isOpen: boolean;

  constructor() {
    this.isEnabled = location.host === 'localhost:3000';
    this.isOpen = false;
    this.initDevTools();
    (window as any).toggleDevTools = () => {
      const wasEnabled = this.isEnabled;
      this.isEnabled = !this.isEnabled;
      if (wasEnabled) {
        this.destroyDevTools();
      } else {
        this.initDevTools();
      }
    };
  }

  private getDevToolsAnchor() {
    return document.querySelector('[dev-type="anchor"]') as HTMLElement;
  }

  private getDevToolsDrawer() {
    return document.querySelector('[dev-type="drawer"]') as HTMLElement;
  }

  private createElement(type: string, css: CSSProperties, tags: {key: string, value: string}[], addTo?: string) {
    const div = document.createElement(type);
    Object.entries(css).forEach(([key, value]: [any, any]) => {
      div.style[key] = value;
    });
    tags.forEach(tag => {
      div.setAttribute(tag.key, tag.value);
    });
    if (addTo) {
      const addToElement = document.querySelector(addTo);
      if (addToElement) {
        addToElement.appendChild(div);
      }
    }
    return div;
  }

  private handleToggleDrawer() {
    const anchor = this.getDevToolsAnchor();
    const drawer = this.getDevToolsDrawer();
    if (anchor && drawer) {
      if (this.isOpen) {
        anchor.style.marginLeft = '-1px';
        drawer.style.left = '-301px';
      } else {
        anchor.style.marginLeft = '300px';
        drawer.style.left = '0px';
      }
      this.isOpen = !this.isOpen;

    }
  }

  private initDevTools() {
    if (this.isEnabled && !this.getDevToolsAnchor()) {
      const anchor = this.createElement('div', {
        position: 'fixed',
        left: '-1px',
        bottom: 0,
        width: '10px',
        height: '10px',
        borderTopRightRadius: '5px',
        border: '1px solid black',
        borderLeft: 'none',
        transition: '0.5s',
        color: 'black',
        fontSize: '8px',
        fontWeight: '800',
        textAlign: 'center',
        background: 'lightgray',
        zIndex: 10000
      }, [{
        key: 'dev-type',
        value: 'anchor'
      }], 'body');
      anchor.textContent = '>';
      anchor.addEventListener('click', this.handleToggleDrawer.bind(this));
      const drawer = this.createElement('div', {
        position: 'fixed',
        left: '-301px',
        bottom: 0,
        width: '300px',
        height: '100vh',
        borderRight: '1px solid black',
        transition: '0.5s',
        color: 'black',
        background: 'lightgray',
        zIndex: 9999
      }, [{
        key: 'dev-type',
        value: 'drawer'
      }], 'body');

      this.initDrawerInside(drawer);
    }
  }

  private handleChangeFor(dataKey: string) {
    return () => {
      const data = localStorage.getItem(dataKey) === 'true';
      localStorage.setItem(dataKey, Boolean(!data).toString());
    };
  }

  private initDrawerInside(element: HTMLElement) {
    const switches = SETTINGS_MAP.filter(el => el.type === 'switch').map(setting => {
      return `<p 
        style="font-size: 800;"
      >${setting.label}: 
        <input 
          type="checkbox" 
          dev-type="input" 
          ${localStorage.getItem(setting.key) === 'true' ? 'checked' : ''} 
          data-key="${setting.key}"
        />
      </p>`;
    }).join('\n');

    const displays = SETTINGS_MAP.filter(el => el.type === 'display').map(setting => {
      return `<p style="font-size: 800;">${setting.label}:<br/>
      <div style="background: white; width: 250px; border: 1px solid black; overflow: auto; padding: 5px;">${localStorage.getItem(setting.key)}</div>
      </p>`;
    }).join('\n');

    element.innerHTML = `
    <div style="padding: 10px; overflow: hidden;">
      <p style="width: 100%; font-size: 20px; text-align: center;">Dev Tools</p>
      <hr/>
      <div style="overflow: auto; max-height: calc(100vh - 27px * 3)">
      ${displays}
      <hr/>
      ${switches}
      </div>
    </div>
    `;

    document.querySelectorAll('input[type="checkbox"][dev-type="input"]').forEach(el => {
      el.addEventListener('change', this.handleChangeFor(el.getAttribute('data-key') || ''));
    });
  }

  private destroyDevTools() {
    this.getDevToolsAnchor()?.remove();
    this.getDevToolsDrawer()?.remove();
  }
}