import React, { useEffect } from 'react';
import { InfoBarProps } from './InfoBar.type';
import { useStyles } from './InfoBar.style';
import { Grid, Typography } from '@mui/material';

import DoneIcon from '@mui/icons-material/Done';
import SyncIcon from '@mui/icons-material/Sync';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const INFO_BAR_TEXTS = {
  error: 'Unfortunately we encountered an error.',
  inprogress: 'Saving. Wait...',
  saved: 'Your changes has been saved.',
  errorReport: 'Your error report has been sent',
  success: ''
};

export const INFO_BAR_ICONS_COLOR = {
  error: '#FF3232',
  inprogress: '#0F44FF',
  saved: '#189A34',
  errorReport: '#189A34',
  success: '#189A34',
};

export const INFO_BAR_ICON = {
  error: ErrorOutlineIcon,
  inprogress: SyncIcon,
  saved: DoneIcon,
  errorReport: DoneIcon,
  success: DoneIcon
};

export const InfoBar: React.FC<InfoBarProps> = ({
  type,
  onClick,
  error
}: InfoBarProps) => {
  const classes = useStyles();
  const Icon = type ? INFO_BAR_ICON[type] : null;

  const handleClick = () => {
    if(onClick){
      onClick();
    }
    if(error){
      navigator.clipboard.writeText(error);
    }
  };
  return (
    <>
      {type && (
        <div className={type ? classes[type] : undefined} onClick={handleClick}>
          <Grid 
            container 
            className={
              error ? 
                classes.gridContainerWithError : 
                classes.gridContainer
            }>
            <Grid item>
              {Icon && (
                <Icon fontSize={'small'} style={{ color: INFO_BAR_ICONS_COLOR[type] }} className={classes.iconPadding}/>
              )}
            </Grid>
            <Grid item>
              <Typography variant={'subtitle1'}>
                {INFO_BAR_TEXTS[type]}
                {error ? <>
                  {error.toString().split('\n').map(
                    el => (<> {type !== 'success' && <br/>} {el} </>
                    ))}
                </> : <></>}
                {type === 'error' && 
                  <>
                    <br/> Please use Report Bug tool and describe steps you made, to reproduce this unexpected behavior.
                  </>
                }
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
};
