import { UNAUTHORIZED_URL } from '../Routes/RoutingTable';

const VERRIFICATION_URL_PATTERN = /\/verification\/[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/;
const SELF_SERVE_URL_PATTERN = /\/verification/;

interface GlobalErrorHandler {
  comparator: (data: any) => boolean;
  fn: () => void;
}

const handlersStore: GlobalErrorHandler[] = [
  {
    comparator: (response: any): boolean => {
      return response?.body?.error === 'Not Found' && 
        response?.body?.message === 'Dental Office not found' &&
        response.body?.statusCode === 404;
    },
    fn: () => {
      location.href = '/logout';
    }

  }
];

export const handleErrorGlobally = (data: any) => {
  const handler = handlersStore.find(handler => handler.comparator(data));
  if (handler) {
    handler.fn();
  }
};

export const handleUnauthorized = (path: string) => {
  if (VERRIFICATION_URL_PATTERN.test(path)) {
    location.href = UNAUTHORIZED_URL;
  } else if (SELF_SERVE_URL_PATTERN.test(path)) {
    location.href = UNAUTHORIZED_URL;
  } else {
    location.href = `/login?return=${location.pathname}`;
  }
};